<template>
  <ApplicationPage :provider="$ENUM.ASSISTANCE_PROVIDERS_TYPES.PERSONE"></ApplicationPage>
</template>

<script>
import ApplicationPage from '@component/Page/LkAssistanceProvideApplication/Application.vue';

export default {
  name: 'Application',
  components: {
    ApplicationPage,
  },
};
</script>

<style lang="scss" scoped>

</style>
